<template>
  <div class="welcome-clinician">
    <h1>{{ $t('welcomeToOwl') }}</h1>
    <h5>{{ $t('welcomeIntroMessageClinician') }}</h5>

    <p>{{ $t('welcomeIntroMessageClinicianGettingStarted') }}</p>
    <p>1) {{ $t('welcomeIntroMessageClinicianSub1') }}</p>
    <p>2) {{ $t('welcomeIntroMessageClinicianSub2') }}</p>
    <p>3) {{ $t('welcomeIntroMessageClinicianSub3') }}</p>
    <small>{{ $t('welcomeIntroMessageClinicianDisclaimer') }}</small>
    <router-link
      :to="{ name: 'Home'}"
      class="btn btn-primary welcome-continue"
    >
      {{ $t("continue") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data: () => {
    return {
      measure: true,
      showModal: false,
      welcomeBody: ''
    }
  },
  created () {
    const user = this.$store.getters.loggedInUser
    const clinicName = 'Owl'
    this.welcomeBody = user.isPatient ? this.$t('welcomeIntroMessagePatient', { clinicName: clinicName }) : this.$t('welcomeIntroMessage', { clinicName: clinicName })
    this.$toast.success({ message: this.$t('accontActivatedSuccess') })
  }
}
</script>
